import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { Flex, Box, List, ListItem, ListIcon } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { BsTools } from 'react-icons/bs';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper width={960} mdxType="Wrapper">
      <hr></hr>
  <Heading icon={<BsTools mdxType="BsTools" />} subtitle="Meisterlich umgesetzt" mdxType="Heading">Uhrmacher- und Goldschmied-Service in Siegen</Heading>
      <p>{`  Ihre Uhr tickt nicht mehr richtig? Bei Ihrem lieb gewonnen Schmuckstück ist der Lack ab? Wir lassen Ihre
Lieblingsstücke in neuem Glanz erstrahlen und sorgen für Präzision.
Um die Einzigartigkeit Ihrer Schmuckstücke zu bewahren, brauchen sie Pflege. Bei Juwelier Grimm finden Sie neben
kompetenter Beratung gleich mehrere Experten auf dem Gebiet der Erhaltung, Entstehung und Individualisierung von
Schmuck und Uhren.`}</p>
      <hr></hr>
      <h2 {...{
        "id": "schmuck--und-goldschmiede-service"
      }}>{`Schmuck- und Goldschmiede-Service`}</h2>
      <p>{`  Ein Schmuckstück als Ausdruck der eigenen Persönlichkeit braucht einen Meister, der es entstehen lässt. Mit
Goldschmiedemeister Andreas Grimm sind Ihre Ideen, Wünsche oder Ihr lieb gewonnenes Schmuckstück in den besten Händen.
In unserer Goldschmiedewerkstatt in Siegen wird aus Ihren Vorstellungen Ihr ganz persönliches Schmuckstück. Auch Ihren
Lieblings- oder Erinnerungsstücken geben wir durch eine Umarbeitung neuen, individuellen Glanz. Hierfür bieten wir
Ihnen neben dem Aufziehen, Verlängern und Ergänzen von Perlen-Colliers und Perl-Armbändern auch die Anfertigung,
Änderung, Reparatur oder Gravierung aller Schmuckstücke in Gold, Silber und Platin.`}</p>
      <hr></hr>
      <h2 {...{
        "id": "uhrmacher-service"
      }}>{`Uhrmacher-Service`}</h2>
      <p>{`  Autos und Uhren haben drei Gemeinsamkeiten: Sie bestehen aus scheinbar unzählig vielen Einzelteilen, sind Meisterwerke
und brauchen als solche regelmäßige Inspektionen, um lange Freude zu bereiten. In unserer Uhrmacherwerkstatt in Siegen
sind Uhren – von Normal- über Präzisionsuhren bis hin zu Weckern und Großuhren – ideal aufgehoben. Unser
Uhrmachermeister Michael Grimm widmet sich allen Anliegen mit der gleichen Präzision und Beständigkeit – wie ein
Uhrwerk.`}</p>
      <p>{`  Egal, ob Ihr Zeitmesser einen Batteriewechsel, eine Wasserdichtigkeitsprüfung oder eine Generalüberholung braucht, wir
sorgen gerne dafür, dass die Zeit niemals stillsteht.`}</p>
      <hr></hr>
      <h2 {...{
        "id": "altgoldankauf"
      }}>{`Altgoldankauf`}</h2>
      <p>{`  Ihr letzter Blick ins Schmuckkästchen offenbarte Raum für Erneuerung? Wenn Ihre alten Goldketten, Ringe oder Armbänder
keine Verwendung mehr finden, setzen Sie sie bei uns gewinnbringend ein. Wir kaufen Ihr Altgold gegen bar oder auf
Verrechnung.`}</p>
      <hr></hr>
    </Wrapper>
    <Wrapper mdxType="Wrapper">
  <Flex flexWrap={'wrap'} justifyContent='space-between' mdxType="Flex">
    <Box width={['100%', '100%', '33%', '33%']} p={'6'} mdxType="Box">
      <h3>Uhren Service</h3>
      <List mdxType="List">
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Mechanische Uhren / Handaufzug</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Quarzarmbanduhren</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Automatikuhren</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Batteriewechsel</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Komplette Revisionen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Glasersatz</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Leder-, Metall-, Kuststoffuhrarmbänder</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Bandanpassung</ListItem>
      </List>
    </Box>
    <Box width={['100%', '100%', '33%', '33%']} p={'6'} mdxType="Box">
      <h3>Goldschmiede Service</h3>
      <List mdxType="List">
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Goldschmiedereparaturen aller Art</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Kette und Ösen zulöten, Verschlüsse ersetzen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Ringgrößen ändern</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Perlenketten neu fassen oder knoten</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Trauringumarbeitung</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Neuanfertigungen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Schmuck reinigen und aufarbeiten</ListItem>
      </List>
    </Box>
    <Box width={['100%', '100%', '33%', '33%']} p={'6'} mdxType="Box">
      <h3>Altgoldankauf</h3>
      <List mdxType="List">
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an:</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Zahngold, Goldnuggets, Altgold aus Schmuckstücken und Münzen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Altgoldankauf ist Vertrauenssache</ListItem>
      </List>
    </Box>
  </Flex>
  <CallToAction mdxType="CallToAction" />
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      